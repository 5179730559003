// import { URLSearchParams } from 'url';
import { gql, useMutation } from '@apollo/client';
import { ReactElement, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../common_lib_front/components/inputField/inputField';
import { PopUp } from '../../common_lib_front/components/popUp/popUp';
import useOrderPrice, {
  PAYMENT_METHODS,
  PAYMENT_METHODS_T,
} from '../../common_lib_front/hooks/useOrderPrice';
import {
  formatDate,
  formatDateForInput,
} from '../../common_lib_front/utilities/formatDate';
import { usePaymentSession } from '../../hooks/usePaymentSession';
import { useValidPassInfos } from '../../hooks/useValidPassInfos';
import { InviteGuestDispatchType } from '../../pages/host/inviteGuest/useInviteGuest';
import { InviteGuestForm, RecipientInfo } from '../inviteGuestForm/inviteGuestForm';
import style from './inviteGuestPayment.module.css';
import useSearchParams from './useSearchParams';

const DO_STRIPE_CHARGE = gql`
  mutation DoStripeCharge(
    $cancelUrl: String!
    $successUrl: String!
    $paymentSessionId: String!
    $paymentMethod: String!
  ) {
    doStripeCharge(
      cancelURL: $cancelUrl
      successURL: $successUrl
      paymentSessionId: $paymentSessionId
      paymentMethod: $paymentMethod
    ) {
      success
      error
      url
    }
  }
`;

type DO_STRIPE_CHARGE_VARS = {
  cancelUrl: string;
  successUrl: string;
  paymentSessionId: string;
  paymentMethod?: string;
};

type DO_STRIPE_CHARGE_RES = {
  doStripeCharge: {
    success: boolean;
    error: string;
    url: string;
  };
};

type InviteGuestPaymentProps = {
  data: Array<RecipientInfo>;
  editData: InviteGuestDispatchType;
  fastPassSelected: boolean;
  setFastPassSelected: any;
};

export default function InviteGuestPayment(props: InviteGuestPaymentProps): ReactElement {
  const { data: memoryData, editData, fastPassSelected } = props;
  const history = useHistory();
  const { communityId, stripeFeeAdditional } = useContext(CommunityContext);
  const [paymentSelectionAllowed] = useState<boolean>(
    ['watercolor', 'pinnacleport'].includes(communityId) && !!stripeFeeAdditional,
  );
  const [alert, setAlert] = useState<string>('');
  const [paymentOption, setpaymentOption] = useState<PAYMENT_METHODS_T>(
    PAYMENT_METHODS.CC,
  );

  const [doStripeCharge, { loading }] = useMutation<
    DO_STRIPE_CHARGE_RES,
    DO_STRIPE_CHARGE_VARS
  >(DO_STRIPE_CHARGE);

  const { paymentSessionId } = useSearchParams(memoryData);

  // const { data: rawGuestData } = useGetMyGuests({
  //   registrationIds,
  //   fastPassesOnly: fastPassSelected,
  //   pageNumber: 0,
  //   pageSize: 0,
  // });
  const { data: rawGuestData } = usePaymentSession({
    variables: {
      paymentSessionId: paymentSessionId || '',
    },
    skip: !paymentSessionId,
  });

  const { allPassInfoDatas } = useValidPassInfos({});
  const { featuresConfig: featuresConfig } = useContext(CommunityContext);
  const multiplePassesFeature = useMemo<boolean>(() => {
    const res: boolean | undefined = featuresConfig?.host?.inviteGuest?.multiplePasses;
    if (res === undefined) return true;
    return res;
  }, [featuresConfig]);
  // transform guest data into familiar format from guest form
  const data = useMemo(() => {
    return (
      rawGuestData?.getPaymentSession.data?.guests
        // only allow registrations with some unpaid passes
        ?.filter(val =>
          val.passes.some(
            p =>
              p.paid === 'unpaid' ||
              (val?.reservationExtension?.fulfilled === false && p.paid === 'paid') ||
              p.paid === 'no-charge' ||
              p.paid === 'ach-failed',
          ),
        )
        ?.map(val => ({
          firstName: val.guestInfo.firstName,
          lastName: val.guestInfo.lastName,
          companyName: '',
          companyId: '',
          email: val.guestInfo.email,
          phoneNumber: val.guestInfo.phoneNumber,
          registrationId: val.registration.registrationId,
          address: val.communityRental?.address,
          arrivalDate: formatDateForInput(
            val.reservationExtension?.newStartDate ||
              val.communityRental?.arrivalDate ||
              val.passes[0]?.startDate,
          ),
          departureDate: formatDateForInput(
            val.reservationExtension?.newEndDate ||
              val.communityRental?.departureDate ||
              val.passes[0]?.endDate,
          ),
          willPayForPass: true,
          passes: val.passes
            // only allow unpaid and not deleted passes
            .filter(
              pass =>
                ((val.reservationExtension?.fulfilled === false &&
                  pass.paid === 'paid') ||
                  pass.paid === 'unpaid' ||
                  pass.paid === 'ach-failed') &&
                pass.status !== 'invite-deleted' &&
                pass.status !== 'deleted',
            )
            .map(pass => ({
              startDate:
                pass.startDate ||
                val.reservationExtension?.newStartDate ||
                val.communityRental?.arrivalDate,
              endDate:
                pass.endDate ||
                val.reservationExtension?.newEndDate ||
                val.communityRental?.departureDate,
              passInfoId: pass.passInfoId,
              passId: pass.passId,
              registrationId: val.registration.registrationId,
            })),
        })) || []
    );
  }, [rawGuestData]);

  const {
    totalPrice,
    passes,
    fees,
    error: priceError,
  } = useOrderPrice(
    useMemo(
      () =>
        data?.flatMap(recipient =>
          recipient.passes.map(p => ({
            ...p,
            startDate: p.startDate || undefined,
            endDate: p.endDate || undefined,
          })),
        ),
      [data],
    ),
    {
      paymentType: paymentOption,
    },
  );
  const addRental = multiplePassesFeature
    ? (recipientIdx: number): void => {
      if (!multiplePassesFeature) return;
      editData({
        type: 'addRental',
        payload: {
          recipientIdx,
          passData: {
            passInfoId:
                data[recipientIdx]?.passes[0]?.passInfoId ||
                allPassInfoDatas[0]?.passInfoId ||
                '',
            startDate: data[recipientIdx]?.arrivalDate,
            endDate: data[recipientIdx]?.departureDate,
          },
        },
      });
    }
    : undefined;

  return (
    <PopUp
      title="Confirm Purchase &amp; Invite"
      close={() => history.push('/invite-guest/guest-list')}
      largeSize
    >
      <div className={style.box}>
        <GenericAlert color="red" title={alert} hidden={!alert} />
        <GenericAlert color="red" title={priceError} hidden={!priceError} />
        <div className={style.flexBox}>
          <div className={style.recipientBox}>
            {data.map((origRecipientData, recipientIdx) => (
              <InviteGuestForm
                key={recipientIdx}
                idx={recipientIdx}
                data={{
                  ...origRecipientData,
                  passes: origRecipientData.passes.map(p => {
                    console.log(p.startDate, p.endDate);
                    return {
                      ...p,
                      startDate: formatDateForInput(p.startDate) || '',
                      endDate: formatDateForInput(p.endDate) || '',
                    };
                  }),
                }}
                formConfig={{
                  firstName: { required: true },
                  lastName: { required: true },
                  companyName: { hidden: true },
                  email: { required: false },
                  phoneNumber: { required: true },
                  address: {
                    required: true,
                    label: "Rental Address of Guest's Stay",
                  },
                  willPayForPass: {
                    hidden: true,
                  },
                  passType: {},
                }}
                formTextConfig={{
                  recipientTag: 'Reservation',
                }}
                paymentMode
                disabled={true}
                addRental={addRental ? () => addRental(recipientIdx) : undefined}
                editRecipient={recipientData =>
                  editData({
                    type: 'editRecipient',
                    payload: {
                      recipientData,
                      recipientIdx,
                    },
                  })
                }
                editRental={(rentalIdx, rentalData) =>
                  editData({
                    type: 'editRental',
                    payload: {
                      recipientIdx,
                      rentalIdx,
                      passData: rentalData,
                    },
                  })
                }
                deleteRecipient={() =>
                  editData({
                    type: 'deleteRecipient',
                    payload: {
                      recipientIdx,
                    },
                  })
                }
              />
            ))}
          </div>
          <div className={style.paymentBox}>
            <div className={style.paymentDetails}>
              {(passes || []).map(pass => (
                <div className={style.paymentDetailItem} key={pass.passInfoId}>
                  <div className={style.passNum}>
                    {allPassInfoDatas.find(pinfo => pinfo.passInfoId === pass.passInfoId)
                      ?.name ||
                      (communityId !== 'watercolor' ? 'Access Pass' : 'Wristbands Fee')}
                  </div>
                  <table className={style.table}>
                    <thead>
                      <tr>
                        <th>Start Date</th>
                        <th className={style.shortTh}>Valid Through</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className={style.firstRow}>
                        <td>
                          {formatDate(
                            new Date(
                              (() => {
                                const rentalIGuess = data.find(
                                  r =>
                                    r.passes.find(p => p.passId === pass.passId)
                                      ?.startDate,
                                );
                                const thePass = rentalIGuess?.passes?.find(
                                  p => p.passId === pass.passId,
                                );
                                return thePass?.startDate || '';
                              })(),
                            ),
                          )}
                        </td>
                        <td>
                          {formatDate(
                            new Date(
                              (() => {
                                const rentalIGuess = data.find(
                                  r =>
                                    r.passes.find(p => p.passId === pass.passId)?.endDate,
                                );
                                const thePass = rentalIGuess?.passes?.find(
                                  p => p.passId === pass.passId,
                                );
                                return thePass?.endDate || '';
                              })(),
                            ),
                          )}
                        </td>
                      </tr>
                      <tr className={style.secondRow}>
                        <th></th>
                        <td>${pass.price?.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                  {/* <div className={style.totalBox}>
                      <h4>Total</h4>
                      <h4>{paymentTotal}</h4>
                    </div> */}
                </div>
              ))}
              {(fees || []).map(fee => (
                <div className={style.paymentDetailItem} key={fee.name}>
                  <div className={style.passNum}>{fee.name || 'Sercive Fee'}</div>
                  <table className={style.table}>
                    <tbody>
                      <tr className={style.secondRow}>
                        <th></th>
                        <td>${fee.amount?.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
              {paymentSelectionAllowed && (
                <div className={style.container_option}>
                  <h6 className={style.box__subTitle}>Select Payment Method</h6>
                  <div className={style.inputLong}>
                    <div className={style.box__inputLong}>
                      <div className={style.box__inputField}>
                        <InputField
                          smallSize
                          highlightOnFocus
                          htmlFor="option-2"
                          labelTitle="Credit Card"
                          inputType="radio"
                          inputValue={PAYMENT_METHODS.CC}
                          checked={paymentOption === PAYMENT_METHODS.CC}
                          radio={true}
                          changeHandler={() => {
                            setpaymentOption(PAYMENT_METHODS.CC);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={style.inputLong}>
                    <div className={style.box__inputLong}>
                      <div className={style.box__inputField}>
                        <InputField
                          smallSize
                          highlightOnFocus
                          htmlFor="option-1"
                          labelTitle="US Bank Account(ACH)"
                          inputType="radio"
                          inputValue={PAYMENT_METHODS.ACH}
                          checked={paymentOption === PAYMENT_METHODS.ACH}
                          radio={true}
                          changeHandler={() => {
                            setpaymentOption(PAYMENT_METHODS.ACH);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className={style.totalBox}>
                <h4>Subtotal</h4>
                <h4>${totalPrice.toFixed(2)}</h4>
              </div>
            </div>
          </div>
        </div>
        <div className={style.btnBox}>
          <div className={style.btn}>
            <GenericButton
              color="blue"
              size="large"
              title="Confirm Purchase &amp; Invite"
              disabled={loading}
              clickHandler={() => {
                if (loading) {
                  return;
                }
                if (totalPrice > 0) {
                  doStripeCharge({
                    variables: {
                      cancelUrl: window.location.href,
                      paymentMethod: paymentOption,
                      // successUrl: `${window.location.origin}/${communityId}/invite-guest/receipt`,
                      successUrl: `${
                        window.location.origin
                      }/${communityId}/invite-guest/${
                        fastPassSelected ? 'fast-pass-list' : 'guest-list'
                      }`,
                      paymentSessionId: paymentSessionId || '',
                    },
                  })
                    .then(res => {
                      if (res.data?.doStripeCharge.url) {
                        window.location.href = res.data.doStripeCharge.url;
                      } else {
                        setAlert(res.data?.doStripeCharge.error || '');
                      }
                    })
                    .catch(err => {
                      setAlert(err.message);
                    });
                } else {
                  history.push(
                    `/invite-guest/${fastPassSelected ? 'fast-pass-list' : 'guest-list'}`,
                  );
                }
              }}
            />
          </div>
        </div>
      </div>
    </PopUp>
  );
}
